import React from 'react';

import { Card, CardContent, Grid } from '@mui/material';

import Footer from './components/Footer';
import Navbar from './components/Navbar';

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import axios from 'axios';

function Blogs(props) {

    const [containerRef,containerInView] = useInView({
        triggerOnce : true,
    });

    const [posts,setPosts] = React.useState([]);

    React.useEffect(()=>{
        axios.get("https://public-api.wordpress.com/rest/v1/sites/motleyscape.wordpress.com/posts")
        .then((resp)=>{
            setPosts(resp.data.posts);
        }).catch((error)=>{
            console.log(error);
        })
    },[])

    return (
        <div>
            <div className='pad-10'>
                <h1 className='mFont' style={{textAlign:'center',fontWeight:900}}>Blogs</h1>

                <Grid container spacing={2} style={{marginTop:10}} ref={containerRef}>
                    {
                        posts.map(d =>{
                            var date = d.date.slice(0,10);

                            return(
                                <Grid item sm={12} lg={4}>
                                    <motion.div animate={{y: containerInView && 0 }} transition={{from:200,duration:2}}>
                                    <Card sx={{ minWidth: 345 }}>
                                        <CardContent>
                                            <h2 className='mFont'>{d.title}</h2>
                                            <h5 className="mFont">{date}</h5>
                                            <p className='mFont'>{d.excerpt.slice(3,200)}...</p>
                                            <div style={{marginTop:30}}>
                                                <a type="button" style={{textDecoration:'none'}} href={"/blog/" + d.ID} className="mFont readMoreBtn readBlog">Read More</a>
                                            </div>
                                        </CardContent>
                                        
                                    </Card>
                                    </motion.div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
            <Navbar 
                homeRef={props.homeRef} 
                aboutRef={props.aboutRef} 
                techRef={props.techRef} 
                indusRef={props.indusRef} 
                contactRef={props.contactRef} 
                scrollToRef={props.scrollToRef}
                />
            <Footer style={{marginTop:30}}/>
        </div>
    );
}

export default Blogs;