import React from 'react';

import Contact from "./components/Contact";
import HomeComponent from "./components/HomeComponent";
import Industries from "./components/Industries";
import UnderstandingComponent from "./components/UnderstandingComponent";
import WhyComponent from "./components/WhyComponent";

import TriangleImg from "../assets/triangle.png";

import Footer from "./components/Footer";

import { Dialog, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import colors from "../helper/colors";

function MainDiv(props) {

    const homeRef = props.homeRef;
    const aboutRef = props.aboutRef;
    const techRef = props.techRef;
    const indusRef = props.indusRef;
    const contactRef = props.contactRef;

    const [hovered,setHovered] = React.useState(false);
        
    const [openNav,setNav] = React.useState(false);

    function handleNav(){
        if(openNav){
            setNav(false);
        }else{
            setNav(true);
        }
    }

    return (
    <div>
        
        <div>
            <HomeComponent reference={homeRef}/>
            <div className="divCenter" style={{ marginTop: -30 }}>
                <img src={TriangleImg} width={20} alt="Triangle" />
            </div>
            <WhyComponent reference={aboutRef}/>
            <UnderstandingComponent reference={techRef}/>
            <Industries reference={indusRef}/>
            <Contact reference={contactRef}/>
        </div>

        <div className="navBar">
          <div>
            <Dialog className='dialogBack' keepMounted open={openNav} onClose={handleNav} fullScreen>
                <DialogContent className='dialogBack' style={{paddingTop:'15%'}}>
                    <div className='divCenter'>
                        <button className="mFont navBtn" 
                            onClick={()=>{
                                setNav(false);
                                props.scrollToRef(homeRef);
                            }}
                            >Home</button>
                    </div>
                    <div className='divCenter'>
                        <button className="mFont navBtn aboutus" 
                            onClick={()=>{
                                setNav(false);
                                props.scrollToRef(aboutRef);
                            }}>About Us</button>
                    </div>
                    <div className='divCenter'>
                        <a type="button" style={{textDecoration:"none"}} href="/blogs" className="mFont navBtn blogs">Blogs</a>
                    </div>
                    <div className='divCenter'>
                        <button className="mFont navBtn technology" 
                            onClick={()=>{
                                setNav(false);
                                props.scrollToRef(techRef);
                                }}>Technology</button>
                    </div>
                    <div className='divCenter'>
                        <button className="mFont navBtn testimonial" 
                            onClick={()=>{
                                setNav(false);
                                props.scrollToRef(indusRef);
                            }}>Industries</button>
                    </div>
                    <div className='divCenter'>
                        <a type="button" style={{textDecoration:"none"}} href="/works" className="mFont navBtn ourwork">Our Work</a>
                    </div>
                    <div className='divCenter'>
                        <a type="button" style={{textDecoration:"none"}} href="/products" className="mFont navBtn productservices">Products {"&"} Services</a>
                    </div>
                    <div className='divCenter'>
                        <a type="button" className="mFont navBtn contactus" 
                            href="/contact">Contact Us</a>
                    </div>
                    <button style={{position:'absolute',top:20,right:20,background:"transparent",border:"none",cursor:"pointer"}} onClick={()=>{setNav(false);}}><CloseIcon style={{color:colors.black}}/></button>
                </DialogContent>
            </Dialog>
            <button type='button' onClick={handleNav} style={{border:"none",background:'transparent',cursor:'pointer'}}>
            <div>
                <svg onMouseEnter={()=>{setHovered(true)}} onMouseLeave={()=>{setHovered(false);}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <g id="Rectangle_3" data-name="Rectangle 3" fill={hovered ? colors.secondary : colors.white} stroke="none" strokeWidth="2.5">
                        <rect width="30" height="30" rx="5" stroke="none"/>
                        <rect x="1.25" y="1.25" width="27.5" height="27.5" rx="3.75" fill="none"/>
                    </g>
                    <line id="Line_39" data-name="Line 39" x2="15" transform="translate(8 11)" fill="none" stroke={hovered ? colors.white : colors.secondary} strokeLinecap="round" strokeWidth="5"/>
                    <line id="Line_40" data-name="Line 40" x2="15" transform="translate(8 20)" fill="none" stroke={hovered ? colors.white : colors.secondary} strokeLinecap="round" strokeWidth="5"/>
                </svg>
            </div>
            </button>
          </div>
        </div>
        
        <Footer/>
      </div>
    );
}

export default MainDiv;