import React from "react";

import "../../styles/home.css";

import IconLight from "../../assets/iconLight.png";
import IconName from "../../assets/iconname.png";

import BgImg from "../../assets/bg.png";

const HomeComponent = (props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${BgImg})`,
        width: "100%",
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed"
      }}
      ref={props.reference}
    >
      <div className="backgroundTransparent">
        <div
          className="backgroundTransparent pd-top-bottom"
        >
          <div className="backgroundTransparent">
            <div className="divCenter backgroundTransparent">
              <div className="backgroundTransparent">
                <div className="divCenter backgroundTransparent">
                  <img
                    src={IconLight}
                    className="iconHome"
                    height="auto"
                    alt="logo"
                  />
                </div>
                <div className="divCenter backgroundTransparent">
                  <img
                    src={IconName}
                    className="iconNameHome"
                    height="auto"
                    alt="logo"
                  />
                </div>
                <div className="divCenter backgroundTransparent">
                  <div className="backgroundTransparent">
                    <p className="HomeTxtHead">AR | VR | XR</p>
                    <p className="HomeTxtHead">Rediscovering Vision</p>
                    <div className="divCenter backgroundTransparent">
                      <a type="button" href="/works" style={{textDecoration:"none"}} className="mFont workBtn ourwork">Our Work</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
