import React from 'react';

import Footer from './components/Footer';
import Navbar from './components/Navbar';

import axios from 'axios';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { IconButton } from '@mui/material';

function Blog(props) {

    const [post,setPost] = React.useState(null);
    React.useEffect(()=>{
        var postId = window.location.pathname.split('/')[2];
        axios.get(
            "https://public-api.wordpress.com/rest/v1/sites/motleyscape.wordpress.com/posts/" +postId
        ).then((resp)=>{
            setPost(resp.data);
        }).catch(error=>{
            console.log(error);
        })
    },[])

    const [containerRef,containerInView] = useInView({
        triggerOnce : true,
    });

    return (
        <div>
            {
                post !== null &&
                <div className='pad-10' ref={containerRef}>
                    <h1 className='mFont' style={{textAlign:'center',fontWeight:900,marginBottom:50}}>{post.title}</h1>

                    <motion.div animate={{y: containerInView && 0 }} transition={{from:200,duration:2}}>

                        <div className='mFont' style={{textAlign:'justify'}} dangerouslySetInnerHTML={{__html:post.content}}></div>
                    
                        <div style={{marginTop:30}}>
                            <IconButton href="/blogs" className="mFont"><ArrowBackIcon style={{marginRight:10}}/>Blogs</IconButton>
                        </div>

                    </motion.div>
                </div>
            }
            
            <Navbar 
                homeRef={props.homeRef} 
                aboutRef={props.aboutRef} 
                techRef={props.techRef} 
                indusRef={props.indusRef} 
                contactRef={props.contactRef} 
                scrollToRef={props.scrollToRef}
                />
            <Footer/>     
        </div>
    );
}

export default Blog;