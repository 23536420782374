import React from "react";

import { Card, CardContent, CardMedia, Grid } from "@mui/material";

import AECImg from '../../assets/aec.png';
import AutoImg from '../../assets/automobile.jpg';
import EduImg from '../../assets/education.jpg';
import GameImg from '../../assets/gaming.jpg';

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Industries(props) {

  // const containerRef = React.useRef(null);
  const [containerRef,containerInView] = useInView({
    triggerOnce : true,
  });

  const cardData = [
    {
      img:AECImg,
      alt:"aecimg",
      txt:"AEC & Real Estate"
    },
    {
      img:EduImg,
      alt:"eduimg",
      txt:"Education"
    },
    {
      img:GameImg,
      alt:"gameimg",
      txt:"Gaming"
    },
    {
      img:AutoImg,
      alt:"autoimg",
      txt:"Automobile"
    }
  ];

  return (
    <div className="whyComponent pad-10" id="industries">
      <h1 className="mFont" style={{ textAlign: "center" }} ref={props.reference}>
        Industries
      </h1>
      <Grid container ref={containerRef} spacing={2} style={{marginTop:30}}>
        {
          cardData.map((cData,index)=>(
            <Grid item lg={3} xs={12} key={index}>
                <motion.div animate={{y: containerInView && 0 }} transition={{from:200,duration:2}}>
                <Card>
                  <CardMedia
                    component="img"
                    height="240"
                    image={cData.img}
                    alt={cData.alt}
                  />
                  <CardContent>
                    <h2 className="mFont" style={{ textAlign: "center" }}>
                      {cData.txt}
                    </h2>
                  </CardContent>
                </Card>
                </motion.div>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
}

export default Industries;
