import React from "react";

import { Grid } from "@mui/material";

import colors from "../../helper/colors";

import vrVideo from "../../assets/video.mp4";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function UnderstandingComponent(props) {

  // const containerRef = React.useRef(null);
  const [containerRef,containerInView] = useInView({
    triggerOnce : true,
  });

  return (
    <div className="whyComponent" id="tech">
      <Grid container>
        <Grid
          item
          lg={6}
          xs={12}
          className="pad-10"
          style={{ background: colors.secondary }}
          ref={containerRef}
        >
          
            <motion.h1 animate={{x: containerInView && 0 }} transition={{from:-200,duration:2}} className="mFont textWhite">Augmented Reality?</motion.h1>
          
            <motion.p animate={{x: containerInView && 0 }} transition={{from:-200,duration:2}} className="mFont whatMotley textWhite">
              Augmented reality (AR) is an enhanced version of the real physical
              world that is achieved through the use of digital visual elements,
              sound, or other sensory stimuli delivered via technology.
              <br />
              The idea here is to create immersive 3D environments. With the
              advancements of Technology, AR is even possible on low-end smart
              phones.
            </motion.p>
          
            <motion.h1 animate={{x: containerInView && 0 }} transition={{from:-200,duration:2}} style={{ marginTop: 40 }} className="mFont textWhite">
              Virtual Reality ?
            </motion.h1>
          
            <motion.p animate={{x: containerInView && 0 }} transition={{from:-200,duration:2}} className="mFont whatMotley textWhite">
              Virtual Reality (VR) is a computer-generated environment with scenes
              and objects that appear to be real, making the user feel they are
              immersed in their surroundings.
              <br />
              Virtual Reality will shape our future as the world will be more connected and transparency will increase.
            </motion.p>
          
            <motion.div animate={{x: containerInView && 0 }} transition={{from:-200,duration:2}} style={{ display: "flex", flexDirection: "row" }}>
              <p className="mFont textWhite">
                To know more about technology checkout {">>"}
                <a
                  href="/blogs"
                  className="mFont"
                  style={{ marginLeft: 10 }}
                >
                  Our Blogs
                </a>
              </p>
            </motion.div>
          
        </Grid>
        <Grid item lg={6} xs={12} ref={props.reference}>
          <video width="100%" height="100%" loop autoPlay muted style={{border:"none"}} playsInline>
            <source
              style={{border:"none"}}
              src={vrVideo}
              type="video/mp4"
            ></source>
          </video>
        </Grid>
      </Grid>
    </div>
  );
}

export default UnderstandingComponent;
