import React from 'react';

import colors from '../../helper/colors';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailIcon from '@mui/icons-material/Mail';
import YouTubeIcon from '@mui/icons-material/YouTube';

function RightLinks(props) {
    return (
        <div>
            <div className="attachRight">

                <button className="attachRightBtn linkedin" onClick={()=>{window.open("https://www.linkedin.com/company/motleyscape")}}><LinkedInIcon style={{color:colors.white}}/></button><br/>
                <button className="attachRightBtn facebook" onClick={()=>{window.open("https://www.facebook.com/motleyscape/")}}><FacebookIcon style={{color:colors.white}}/></button><br/>
                <button className="attachRightBtn instagram" onClick={()=>{window.open("https://www.instagram.com/motleyscape/")}}><InstagramIcon style={{color:colors.white}}/></button><br/>
                <button className="attachRightBtn youtube" onClick={()=>{window.open("https://www.youtube.com/channel/UC5o-mVGC6Vhi5tlCg4y9qVg")}}><YouTubeIcon style={{color:colors.white}}/></button><br/>
                <button className="attachRightBtn twitter" onClick={()=>{window.open("https://twitter.com/motleyscape")}}><TwitterIcon style={{color:colors.white}}/></button><br/>
                <button className="attachRightBtn whatsapp" onClick={()=>{window.open("//api.whatsapp.com/send?phone=917447654545&text=Hello, I want to enquire about ")}}><WhatsAppIcon style={{color:colors.white}}/></button><br/>
                <button className="attachRightBtn email" onClick={()=>{window.open('mailto:hello@motleyscape.com')}}><MailIcon style={{color:colors.white}}/></button><br/>
            </div>
        </div>
    );
}

export default RightLinks;