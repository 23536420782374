import React from "react";

import colors from "../helper/colors";

import axios from "axios";
import { Grid, Alert, Button, TextField } from "@mui/material";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function Contact(props) {

  // const containerRef = React.useRef(null);

  const [containerRef,containerInView] = useInView({
    triggerOnce : true,
  });

  const [formData,setFormData] = React.useState({
    Name:"",
    Email:"",
    Message:""
  });
  const [pressed,setPressed] = React.useState(false);
  const [alert,setAlert] = React.useState({
      alertMsg:'',
      alertType:'',
  });

  const showAlert = (msg,type) =>{
      setAlert({alertType:type,alertMsg:msg});
      setTimeout(()=>{
          setAlert({alertType:'',alertMsg:''});
      },3000);
  };

  const onSubmit = () =>{
    setPressed(true);
    if(formData.Name === null || formData.Name === "" || formData.Name === undefined){
        showAlert('Name field is required.','error');
    }
    else if(formData.Email === null || formData.Email === "" || formData.Email === undefined){
        showAlert('Email field is required.','error');
    }
    else if(formData.Message === null || formData.Message === "" || formData.Message === undefined){
        showAlert('Message field is required.','error');
    }
    else {
        
        axios.post("https://api.motleyscape.com",formData,{
            headers:{
                "Content-Type":"application/json",
                "Access-Control-Allow-Origin":"*"
            }
        }).then((mdata)=>{
            showAlert(mdata.data,'success');
            setPressed(false);
            setFormData({
                Name:null,
                Email:null,
                Message:null
            });
        }).catch((err)=>{
            showAlert('Unable to send message. Please try again later','error');
        });
    }
  };

  return (
    <div>
      <div className="whyComponent pad-10" id="contact">
        <h1 className="mFont" style={{ textAlign: "center" }}>
          Contact Us
        </h1>
        <Grid container spacing={2} style={{marginTop:30}} ref={containerRef}>
          <Grid item lg={6} xs={12}>

            {
              alert.alertType !== null &&
              alert.alertType !== '' &&
              alert.alertMsg !== null &&
              alert.alertMsg !== ''  &&
              <Alert severity={alert.alertType}>{alert.alertMsg}</Alert>
            }
            
            <motion.div animate={{x: containerInView && 0 }} transition={{from:-200,duration:2}}>
              <div>
                <TextField
                    error={pressed && (formData.Name === null || formData.Name === "" || formData.Name === undefined)}
                    helperText={pressed && (formData.Name === null || formData.Name === "" || formData.Name === undefined) && "Name field is required."}
                    type='text'
                    variant='outlined'
                    placeholder='Enter your name'
                    label='Name'
                    onChange={(e)=>{setFormData({...formData,Name:e.target.value});}}
                    value={formData.Name}
                    fullWidth
                />
                <TextField
                    error={pressed && (formData.Email === null || formData.Email === "" || formData.Email === undefined)}
                    helperText={pressed && (formData.Email === null || formData.Email === "" || formData.Email === undefined) && "Email field is required."}
                    type='email'
                    variant='outlined'
                    placeholder='Enter your email'
                    label='Email Id'
                    style={{marginTop:30}}
                    onChange={(e)=>{setFormData({...formData,Email:e.target.value});}}
                    value={formData.Email}
                    fullWidth
                />
                <TextField
                    error={pressed && (formData.Message === null || formData.Message === "" || formData.Message === undefined)}
                    helperText={pressed && (formData.Message === null || formData.Message === "" || formData.Message === undefined) && "Message field is required."}
                    type='text'
                    variant='outlined'
                    placeholder='Enter your message'
                    label='Message'
                    style={{marginTop:30}}
                    onChange={(e)=>{setFormData({...formData,Message:e.target.value});}}
                    value={formData.Message}
                    fullWidth
                />
              </div>
              <div style={{marginTop:20}} className="divCenter">
                  <Button fullWidth onClick={onSubmit} variant='contained' style={{marginBottom:20,padding:10,paddingLeft:20,paddingRight:20,background:colors.secondary}}>Send Enquiry</Button>
              </div>
            </motion.div>
           
          </Grid>
          <Grid item lg={6} xs={12} ref={props.reference}>
            
              <motion.div animate={{x:containerInView && 0}} transition={{from:200,duration:2}}>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.7067698352253!2d73.9133461148123!3d18.45161828744919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2ebe357dfa573%3A0xe182d386047bb1f3!2sMotleyscape!5e0!3m2!1sen!2sin!4v1651671506221!5m2!1sen!2sin" 
                  width="100%" 
                  height="300" 
                  style={{border:0}} 
                  allowFullScreen 
                  loading="lazy" 
                  referrerPolicy="no-referrer-when-downgrade">
                </iframe>
                <Grid container>
                  <Grid item lg={4} xs={6}>
                    <h3 className="mFont" style={{textAlign:"center"}}>Address</h3>
                    <p className="mFont" style={{textAlign:"center"}}>Motleyscape, Undri, Pune - 411060</p>
                  </Grid>
                  <Grid item lg={4} xs={6}>
                    <h3 className="mFont" style={{textAlign:"center"}}>Phone No.</h3>
                    <p className="mFont" style={{textAlign:"center"}}><a className="callphone" style={{color:colors.black}} href="tel:+919158610077">+91 91586 10077</a></p>
                    <p className="mFont" style={{textAlign:"center"}}><a className="callphone" style={{color:colors.black}} href="tel:+919730525275">+91 97305 25275</a></p>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <h3 className="mFont" style={{textAlign:"center"}}>Email id</h3>
                    <p className="mFont" style={{textAlign:"center"}}><a className="email" style={{color:colors.black}} href="mailto:hello@motleyscape.com">hello@motleyscape.com</a></p>
                  </Grid>
                </Grid>
              </motion.div>
            
          </Grid>
        </Grid>
      </div>

      <Navbar 
        homeRef={props.homeRef} 
        aboutRef={props.aboutRef} 
        techRef={props.techRef} 
        indusRef={props.indusRef} 
        contactRef={props.contactRef} 
        scrollToRef={props.scrollToRef}
        />
      <Footer/>
    </div>
  );
}

export default Contact;
