import React from 'react';
import axios from 'axios';

import { Card, CardContent, Grid, TextField } from '@mui/material';

import Footer from './components/Footer';
import Navbar from './components/Navbar';

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Work(props) {

    // const containerRef = React.useRef(null);
    const [containerRef,containerInView] = useInView({
        triggerOnce : true,
    });
    
    const [projects,setProjects] = React.useState({});

    const [SearchText,setSearchTxt] = React.useState("");

    React.useEffect(()=>{
        axios.get("https://api.motleyscape.com/displayprojects",{
            headers:{
                "Content-Type":"application/json",
                "Access-Control-Allow-Origin":"*"
            }}).then((resp)=>{
            setProjects(resp.data);
        }).catch((error)=>{
            console.log(error);
        })
    },[]);

    return (
        <div>
            <div className='pad-10'>
                <h1 className='mFont' style={{textAlign:'center',fontWeight:900}}>Our Work</h1>
                <div style={{marginTop:30,marginBottom:30}}>
                    <TextField 
                        type='text'
                        variant='outlined'
                        fullWidth 
                        placeholder='Search by Project Name...' 
                        label='Search by Project Name' 
                        value={SearchText} 
                        onChange={(e)=>{setSearchTxt(e.target.value);}} 
                    />
                </div>

                <Grid container spacing={2} style={{marginTop:10}} ref={containerRef}>
                {
                    projects.Items !== undefined &&
                    projects.Items.length > 0 &&
                    projects.Items.map(proj=>(
                        proj.ProjectName.toLowerCase().search(SearchText.toLowerCase()) !== -1 &&
                        
                        <Grid item lg={4} sm={12}>
                            <motion.div animate={{y: containerInView && 0 }} transition={{from:200,duration:2}}>
                                <Card sx={{ minWidth: 345 }}>
                                    <CardContent>
                                        {
                                            proj.videoUrl ?
                                            <iframe 
                                                width="100%" 
                                                height="312px" 
                                                src={proj.videoUrl+"?mute=1"} 
                                                frameborder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen>

                                            </iframe> :

                                            <div style={{height:'300px',textAlign:'center'}}>
                                                <p style={{paddingTop:'140px'}} className='mFont'>Sorry can't showcase due to project terms.</p>
                                            </div>

                                        }
                                        <h3 className='mFont'>{proj.ProjectName}</h3>
                                        <h5 className='mFont'>{proj.Company}</h5>
                                        <h5 className='mFont'>{proj.sector}</h5>
                                        <p className='mFont'>{proj.Type}</p>
                                    </CardContent>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))
                }
                </Grid>
            </div>

            
            <Navbar 
                homeRef={props.homeRef} 
                aboutRef={props.aboutRef} 
                techRef={props.techRef} 
                indusRef={props.indusRef} 
                contactRef={props.contactRef} 
                scrollToRef={props.scrollToRef}
                />
            <Footer/>
        </div>
    );
}

export default Work;