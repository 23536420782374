import React from "react";

import "../styles/app.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainDiv from "./MainDiv";
import Work from "./Work";
import ProductsServices from "./ProductsServices";
import Terms from "./legal/Terms";
import Privacy from "./legal/Privacy";
import Error404 from "./Error404";
import Cards from "./Cards";
import Blogs from "./Blogs";
import Blog from "./Blog";
import Contact from "./Contact";

function App() {

  const homeRef = React.useRef( null );
  const aboutRef = React.useRef( null );
  const techRef = React.useRef( null );
  const indusRef = React.useRef( null );
  const contactRef = React.useRef( null );

  const scrollToRef = (ref) => window.scrollTo({top:ref.current.offsetTop,behavior:'smooth'});

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainDiv homeRef={homeRef} aboutRef={aboutRef} techRef={techRef} indusRef={indusRef} contactRef={contactRef} scrollToRef={scrollToRef}/>}/>
          <Route path="/blogs" element={<Blogs homeRef={homeRef} aboutRef={aboutRef} techRef={techRef} indusRef={indusRef} contactRef={contactRef} scrollToRef={scrollToRef}/>}/>
          <Route path="/blog/:id" element={<Blog homeRef={homeRef} aboutRef={aboutRef} techRef={techRef} indusRef={indusRef} contactRef={contactRef} scrollToRef={scrollToRef}/>}/>
          <Route path="/works" element={<Work homeRef={homeRef} aboutRef={aboutRef} techRef={techRef} indusRef={indusRef} contactRef={contactRef} scrollToRef={scrollToRef}/>}/>
          <Route path="/products" element={<ProductsServices homeRef={homeRef} aboutRef={aboutRef} techRef={techRef} indusRef={indusRef} contactRef={contactRef} scrollToRef={scrollToRef}/>}/>
          <Route path="/contact" element={<Contact homeRef={homeRef} aboutRef={aboutRef} techRef={techRef} indusRef={indusRef} contactRef={contactRef} scrollToRef={scrollToRef}/>}/>
          <Route path="/tnc" element={<Terms homeRef={homeRef} aboutRef={aboutRef} techRef={techRef} indusRef={indusRef} contactRef={contactRef} scrollToRef={scrollToRef}/>}/>
          <Route path="/privacy" element={<Privacy homeRef={homeRef} aboutRef={aboutRef} techRef={techRef} indusRef={indusRef} contactRef={contactRef} scrollToRef={scrollToRef}/>}/>
          <Route path="/cards" element={<Cards/>}/>
          <Route path="*" element={<Error404/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
