import React from "react";

import { Grid } from "@mui/material";

import modelLogo from "../../assets/model.glb";

import "../../styles/why.css";

import colors from '../../helper/colors';

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

import BotAudio from '../../assets/BotVoice.mp3';


function WhyComponent(props) {

  // const containerRef = React.useRef(null);
  const [containerRef,containerInView] = useInView({
    triggerOnce : true,
  });

  const [playing, setPlaying] = React.useState(false);

  function Sync(selector, audioSelector) {
      
      var sound = document.querySelector(audioSelector);
      var motleyscapeViewer = document.querySelector(selector);
      if (motleyscapeViewer && sound) {
        sound.addEventListener("timeupdate", () => {
          if (sound.currentTime > motleyscapeViewer.duration) {
            // restart both model and sound
            motleyscapeViewer.currentTime = 0;
            sound.currentTime = 0;
          } else {
            motleyscapeViewer.currentTime = sound.currentTime;
          }
        });
      }

      motleyscapeViewer.setAttribute("camera-controls", true);
      motleyscapeViewer.setAttribute("auto-rotate", true);
      motleyscapeViewer.setAttribute("autoplay", true);

      if(playing){
          sound.pause();
          motleyscapeViewer.pause();
      }else{
          sound.play();
          motleyscapeViewer.play();
      }
  }

  return (
    <div id="scrolledDown" className="whyComponent pad-10">
      <Grid container spacing={2} id="aboutUs">
        <Grid item lg={6} xs={12}>
          <div ref={containerRef}>
            
              <motion.h1 animate={{x: containerInView && 0 }} transition={{from:-200,duration:2}} className="mFont">Motleyscape?</motion.h1>
            
              <motion.p animate={{x: containerInView && 0 }} transition={{from:-200,duration:2}} className="mFont whatMotley">
                Motleyscape is an AR | VR | XR based company. We believe in
                bringing change through definite optimism.
                <br />
                We specialize in Augmented and Virtual Reality solutions and
                products across various industries.
                <br />
                Mixed Reality will change the way we look at the world around us.
                Endless applications are possible and we all know imagination is
                the beginning of creation. We use AR, VR, XR and Computer Vision
                to bring imagination into reality.
                <br />
                Visualization is a powerful tool. It has the potential to solve a
                large number of real life use cases and problems. Motleyscape is
                trying to leverage this Visualization tool so that everyone could
                benefit.
                <br />
              </motion.p>
           
              <motion.div animate={{x: containerInView && 0 }} transition={{from:-200,duration:2}} id="about" style={{ display: "flex", flexDirection: "row" }}>
                <p className="mFont">
                  To know more about us checkout {">>"}
                  <a
                    href="/blogs"
                    className="mFont"
                    style={{ marginLeft: 10 }}
                  >
                    Our Blogs
                  </a>
                </p>
              </motion.div>
              
           
          </div>
        </Grid>
        <Grid item lg={6} xs={12} ref={props.reference}>
          <motleyscape-ar
            id="model2"
            src={modelLogo}
            ar="true"
            ar-modes="webxr scene-viewer quick-look"
            auto-rotate="true"
            camera-controls="true"
            bound="tight"
            ar-status="not-presenting"
          ></motleyscape-ar>
          <audio loop id="sound2">
              <source
                  src={BotAudio}
                  type="audio/mpeg"
              />
          </audio>

          <div style={{display:"flex",flexDirection:'row',justifyContent:"center",alignContent:'center',position:'relative',top:-100}}>
              <button style={{border:"none",background:"transparent"}} onClick={
                  ()=>{
                      Sync("#model2","#sound2"); 
                      setPlaying(!playing);
                      }
                  }>
                  {
                      playing
                      ?
                          <PauseCircleFilledIcon style={{fontSize:50,color:colors.secondary}}/>
                      :
                          <PlayCircleIcon style={{fontSize:50,color:colors.blue}}/>
                  }
              </button>
          </div>
        </Grid> 
      </Grid>
    </div>
  );
}

export default WhyComponent;
