import React from 'react';

import modelLogo from '../assets/model.glb';

import colors from '../helper/colors';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';

import BotAudio from '../assets/BotVoice.mp3';

function Cards(props) {

    const [playing, setPlaying] = React.useState(false);

    function Sync(selector, audioSelector) {
        
        var sound = document.querySelector(audioSelector);
        var motleyscapeViewer = document.querySelector(selector);
        if (motleyscapeViewer && sound) {
          sound.addEventListener("timeupdate", () => {
            if (sound.currentTime > motleyscapeViewer.duration) {
              // restart both model and sound
              motleyscapeViewer.currentTime = 0;
              sound.currentTime = 0;
            } else {
              motleyscapeViewer.currentTime = sound.currentTime;
            }
          });
        }

        motleyscapeViewer.setAttribute("camera-controls", true);
        motleyscapeViewer.setAttribute("auto-rotate", true);
        motleyscapeViewer.setAttribute("autoplay", true);

        if(playing){
            sound.pause();
            motleyscapeViewer.pause();
        }else{
            sound.play();
            motleyscapeViewer.play();
        }
    }

    return (
        <div>
            <div>
                <div className="attachRightCard">
                    
                    <button className="attachRightBtn" onClick={()=>{window.open("https://www.motleyscape.com")}}><LanguageIcon style={{color:colors.white}}/></button>
                    <button className="attachRightBtn" onClick={()=>{window.open("https://www.linkedin.com/company/motleyscape")}}><LinkedInIcon style={{color:colors.white}}/></button>
                    <button className="attachRightBtn" onClick={()=>{window.open("//api.whatsapp.com/send?phone=917447654545&text=Hello, I want to enquire about ")}}><WhatsAppIcon style={{color:colors.white}}/></button>
                    <button className="attachRightBtn" onClick={()=>{window.open("https://www.instagram.com/motleyscape/")}}><InstagramIcon style={{color:colors.white}}/></button>
                    <button className="attachRightBtn" onClick={()=>{window.open("https://twitter.com/motleyscape")}}><TwitterIcon style={{color:colors.white}}/></button>
                    <button className="attachRightBtn" onClick={()=>{window.open('mailto:hello@motleyscape.com')}}><MailIcon style={{color:colors.white}}/></button>
                
                </div>
            </div>
            <motleyscape-ar
                style={{width:window.innerWidth,height:window.innerHeight-50}}
                id="model"
                src={modelLogo}
                ar
                ar-modes="webxr scene-viewer quick-look"
                auto-rotate
                camera-controls
                bound="tight"
                shadow-intensity="1" 
            >
            </motleyscape-ar>
            <audio loop id="sound">
                <source
                    src={BotAudio}
                    type="audio/mpeg"
                />
            </audio>

            <div style={{display:"flex",flexDirection:'row',justifyContent:"center",alignContent:'center',position:'relative',top:-100}}>
                <button id="playBtn" style={{border:"none",background:"transparent"}} onClick={
                    ()=>{
                        Sync("#model","#sound"); 
                        setPlaying(!playing);
                        }
                    }>
                    {
                        playing
                        ?
                            <PauseCircleFilledIcon style={{fontSize:50,color:colors.secondary}}/>
                        :
                            <PlayCircleIcon style={{fontSize:50,color:colors.blue}}/>
                    }
                </button>
            </div>
            
        </div>
    );
}

export default Cards;