import React from 'react';

import colors from '../../helper/colors';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailIcon from '@mui/icons-material/Mail';
import YouTubeIcon from '@mui/icons-material/YouTube';

import LogoImg from "../../assets/icon.png";

import RightLinks from './RightLinks';

function Footer(props) {
    return (
        <div>
            <div style={{background:colors.secondary,padding:20}}>
                <div className="divCenter">
                    <img onClick={()=>{window.location="https://www.motleyscape.com"}} style={{cursor:'pointer'}} src={LogoImg} width="50px" height="50px" alt="logo"/>
                </div>

                <RightLinks/>

                <div className="divCenter" style={{marginTop:20}}>
                    <button className='linkedin' style={{border:"none",background:"transparent",cursor:"pointer"}} onClick={()=>{window.open("https://www.linkedin.com/company/motleyscape")}}><LinkedInIcon style={{color:colors.white}}/></button>
                    <button className='facebook' style={{border:"none",background:"transparent",cursor:"pointer"}} onClick={()=>{window.open("https://www.facebook.com/motleyscape/")}}><FacebookIcon style={{color:colors.white}}/></button>
                    <button className='instagram' style={{border:"none",background:"transparent",cursor:"pointer"}} onClick={()=>{window.open("https://www.instagram.com/motleyscape/")}}><InstagramIcon style={{color:colors.white}}/></button>
                    <button className='youtube' style={{border:"none",background:"transparent",cursor:"pointer"}} onClick={()=>{window.open("https://www.youtube.com/channel/UC5o-mVGC6Vhi5tlCg4y9qVg")}}><YouTubeIcon style={{color:colors.white}}/></button><br/>
                    <button className='twitter' style={{border:"none",background:"transparent",cursor:"pointer"}} onClick={()=>{window.open("https://twitter.com/motleyscape")}}><TwitterIcon style={{color:colors.white}}/></button>
                    <button className='whatsapp' style={{border:"none",background:"transparent",cursor:"pointer"}} onClick={()=>{window.open("//api.whatsapp.com/send?phone=917447654545&text=Hello, I want to enquire about ")}}><WhatsAppIcon style={{color:colors.white}}/></button>
                    <button className='email' style={{border:"none",background:"transparent",cursor:"pointer"}} onClick={()=>{window.open('mailto:hello@motleyscape.com')}}><MailIcon style={{color:colors.white}}/></button>
                </div>

                <p className="mFont" style={{textAlign:"center",lineHeight:2,color:colors.white}}>Copyright © {new Date().getFullYear()} Motleyscape.<br/>All Rights Reserved.</p>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                    <a href="/tnc" style={{textDecoration:'none',color:colors.white}} className='mFont'>Terms {'&'} Conditions</a>
                    <a href="/privacy" style={{marginLeft:10,textDecoration:'none',color:colors.white}} className='mFont'>Privacy Policies</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;